import React from 'react';
import PropTypes from 'prop-types';

const CardListItem = ({ children, title }) => {
  return (
    <>
      <dt className="p-treatment-support__card-area-dt">{title}</dt>
      <dd className="p-treatment-support__card-area-dd">{children}</dd>
    </>
  );
};

CardListItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CardListItem;
