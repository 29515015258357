import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardListItem from '../../molecules/health_records/daily_notes/CardListItem';
import userInfiniteScroll from '../../../../hooks/useInfiniteScroll';

const ContentList = ({ listItems }) => {
  return (
    <ul className="p-treatment-support__record-top-consultation-date__list">
      {listItems.length > 0 && listItems.map((listItem, idx) =>
        <li key={idx}>{listItem.text}{listItem.append_text && `(${listItem.append_text})`}</li>
      )}
    </ul>
  );
};

ContentList.propTypes = {
  listItems: PropTypes.array.isRequired
};

const DailyRecord = ({ userHealthRecordId }) => {
  const [csrf, setCsrf] = useState('');
  const [offset, setOffset] = useState(0);
  const [dailyNotes, setDailyNotes] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading, targetRef] = userInfiniteScroll();
  const baseUrl = `/user/health_records/${userHealthRecordId}/daily_notes/load`;
  const limit = 20;

  const featchData = async (csrfToken, url) => {
    if (!hasNext) return;
    if (!csrfToken) {
      csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
      setCsrf(csrfToken);
    }
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-TOKEN': csrfToken
    };

    const queryStr = `offset=${offset}`;

    const response = await fetch(`${url}?${queryStr}`, { headers: headers, method: 'GET' });
    const data = await response.json();

    if (data.has_next) {
      setOffset(n => n + limit + 1);
    } else {
      setHasNext(false);
    }
    setDailyNotes(prevData => [...prevData, ...data.data]);
  };

  useEffect(() => {
    if (!loading) return;

    featchData(csrf, baseUrl);
    setLoading(false);
  }, [loading]);

  return (
    <div className="p-treatment-support__record-top-cardwrap">
      {dailyNotes.length === 0 && !hasNext
        ? (
          <div className="p-treatment-support__card-area-link">
            <p className="p-treatment-support__card-area-date">データが有りません</p>
          </div>
        )
        : (
          dailyNotes.map((dailyNote, idx) => (
            <a href={`/user/health_records/${userHealthRecordId}/daily_notes/upsert?input_date=${dailyNote.input_date}`} key={idx} className="p-treatment-support__card-area-link">
              <p className="p-treatment-support__card-area-date">{dailyNote.format_target_date_with_wday}</p>
              <dl className="p-treatment-support__card-area-dl">
                <CardListItem title="症状">
                  <ContentList listItems={dailyNote.symptoms} />
                </CardListItem>
                <CardListItem title="困り事">
                  <ContentList listItems={dailyNote.problems} />
                </CardListItem>
                <CardListItem title="不安">
                  <ContentList listItems={dailyNote.worry} />
                </CardListItem>
                <CardListItem title="メモ">
                  {dailyNote.memo}
                </CardListItem>
              </dl>
            </a>
          ))
        )}
      <div ref={targetRef}></div>
    </div>
  );
};

DailyRecord.propTypes = {
  userHealthRecordId: PropTypes.number
};

export default DailyRecord;
