import { useState, useEffect, useRef } from 'react';

const userInfiniteScroll = () => {
  const [loading, setLoading] = useState(false);
  const observerRef = useRef(null);
  const targetRef = useRef(null);

  const handleObserver = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setLoading(true);
      }
    });
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });

    const currentObserver = observerRef.current;
    const target = targetRef.current;

    if (target) {
      currentObserver.observe(target);
    }

    return () => {
      if (target) {
        currentObserver.unobserve(target);
      }
    };
  }, []);

  return [loading, setLoading, targetRef];
};

export default userInfiniteScroll;
